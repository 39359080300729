
'use strict';
var app = angular.module('sandhillApp', ['ngAnimate', 'ngSanitize']);
app.filter('limitChar', function () {
    return function (content, length, tail) {
        if (isNaN(length))
            length = 50;

        if (tail === undefined)
            tail = "";

        if (content.length <= length || content.length - tail.length <= length) {
            return content;
        }
        else {
            return String(content).substring(0, length-tail.length) + tail;
        }
    };
});

app.filter('unique', function() {
    return function(collection, keyname) {
        var output = [],
            keys = [];
        angular.forEach(collection, function(item) {
            var key = item[keyname];
            if(keys.indexOf(key) === -1) {
                keys.push(key);
                output.push(item);
            }
        });
        return output;
    };
})
