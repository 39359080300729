app.controller('blogCtrl', ['$scope', '$http', '$filter', function ($scope, $http, $filter) {
    $scope.itemPerPage = 18; // set per page item number (predefine, change also in directive)
    $scope.items = [];
    $scope.count = '';
    $scope.author = [];
    $scope.author_id = [];
    $scope.tag_id = [];
    $scope.category_data = '';
    $scope.category = [];
    $scope.category_id = [];
    $scope.number = '';
    $scope.last_page = '';
    $scope.first = false;
    $scope.last = true;
    $scope.searchString = '';
    $scope.q = '';
    $scope.page_value = '';
    $scope.currentPage = 0;
    $scope.currentValue = 1;
    $scope.paginationSize = 3;
    var min = 0;
    var max = $scope.itemPerPage;
    var url = window.location.href;
    if (url.indexOf("?") > -1) {
        var urlString = url.substr(url.indexOf("?") + 1);
        var urlArray = urlString.split('=');
    }

    /*
     * Fetch all blog post on load
     */

    $http({
        method: 'GET',
        url: '/wp-content/uploads/blog-cache.json'
    }).then(function (response) {
        $scope.tempitems = response.data;
        if ($scope.tempitems) {
            $scope.pagination($scope.tempitems);
        }
        if ($scope.tempitems.length <= $scope.itemPerPage) {
            max = $scope.tempitems.length;
            $scope.itemPerPage = $scope.tempitems.length;
        }
        for (var i = min; i < max; i++) {
            $scope.items.push($scope.tempitems[i]);
        }
    }, function (error) {
    });


    /*
     * Fetch all blog post category on load
     */

    $http({
        method: 'GET',
        url: '/wp-content/themes/sandhill/templates/components/view/category.php'
    }).then(function (response) {
        $scope.category_data = response.data;
    }, function (error) {
    });


    /*
     * Call function on load time before apply any filter
     */

    $scope.pagination = function (str) {
        $scope.count = str.length;
        var str1 = parseInt($scope.count / $scope.itemPerPage);
        var str2 = $scope.count % $scope.itemPerPage;
        if (str2) {
            str2 = 1;
        }
        $scope.pagination_number = str1 + str2;
        $scope.page_no = [];
        for (var i = 1; i <= $scope.pagination_number; i++) {
            $scope.last_page = $scope.pagination_number;
            $scope.page_no.push(i);
        }
    }
    $scope.paginationOffset = function () {
        var range = Math.floor($scope.paginationSize / 2);
        if (($scope.currentValue - range) < 0) {
            return 0;
        }
        else if (($scope.currentValue + range) >= $scope.pagination_number) {
            return ($scope.pagination_number - $scope.paginationSize < 0) ? 0 : $scope.pagination_number - $scope.paginationSize;
        }
        else {
            return $scope.currentValue - range;
        }
    };

    $scope.clear_search = function () {
        $scope.number = 1;
        $scope.currentValue = 1;
        $scope.searchString = '';
        jQuery('.blog-landing .filter-wrap .form-outer form .clear-search').css({'opacity':'0', 'visibility':'hidden'});
    }

    $scope.reinit = function (str) {
        $scope.number = 1;
        $scope.currentValue = 1;
        if (!str) {
            $scope.searchString = '';
        }
    }
    /*
     * Get current active page on click
     */
    $scope.current_page = function (str, event) {
        $scope.number = str;
        $scope.currentValue = $scope.number;
        jQuery('li').removeClass('active');
        jQuery("html, body").animate({scrollTop: jQuery('.blog-landing .filter-wrap .text-center').offset().top}, "slow");
        if (($scope.author_id == '') && ($scope.tag_id == '') && ($scope.category_id == '')) {
            max = $scope.itemPerPage * str;
            min = max - $scope.itemPerPage;

            if (max > $scope.count) {
                max = $scope.count;
            }
            $scope.items = [];
            for (var i = min; i < max; i++) {
                $scope.items.push($scope.tempitems[i]);
            }
            if (str == $scope.last_page) {
                $scope.last = false;
                $scope.first = true;
            }
            else if (str == 1) {
                $scope.last = true;
                $scope.first = false;
            } else {
                $scope.first = true;
                $scope.last = true;
            }
        }
    }

    /*
     * Call when click on next  button
     */

    $scope.page_next = function () {
        $scope.first = true;
        jQuery("html, body").animate({scrollTop: jQuery('.blog-landing .filter-wrap .text-center').offset().top}, "slow");
        var str = parseInt(jQuery('.pagination li.active').text());
        if (str != $scope.pagination_number) {
            var next_active = str + 1;
            $scope.currentValue = next_active;
            jQuery('.pagination li').removeClass('active');
            $scope.current_page(next_active);
            if (next_active == $scope.last_page) {
                $scope.last = false;
            }
        }
    }

    /*
     * Call when click on previous  button
     */

    $scope.page_prev = function () {
        $scope.last = true;
        jQuery("html, body").animate({scrollTop: jQuery('.blog-landing .filter-wrap .text-center').offset().top}, "slow");
        var str = parseInt(jQuery('.pagination li.active').text());
        if (str != 1) {
            var prev_active = str - 1;
            $scope.currentValue = prev_active;
            jQuery('.pagination li').removeClass('active');
            $scope.current_page(prev_active);
            if (prev_active == $scope.last_page) {
                $scope.last = false;
            }
            if (prev_active == 1) {
                $scope.first = false;
            }
        }
    }

    /*
     * Call when click on last  button
     */

    $scope.page_last = function () {
        jQuery('li').removeClass('active');
        jQuery("html, body").animate({scrollTop: jQuery('.blog-landing .filter-wrap .text-center').offset().top}, "slow");
        jQuery('li:nth-child(' + $scope.last_page + ')').addClass('active');
        $scope.current_page($scope.last_page);
        $scope.first = true;
        $scope.last = false;
    }

    /*
     * Call when click on first  button
     */

    $scope.page_first = function () {
        jQuery("html, body").animate({scrollTop: jQuery('.blog-landing .filter-wrap .text-center').offset().top}, "slow");
        jQuery('li').removeClass('active');
        jQuery('li:nth-child(' + 1 + ')').addClass('active');
        $scope.current_page(1);
        $scope.first = false;
        $scope.last = true;
    }

    /*
     * Add active class and remove active class on click of author name
     */

    $scope.remove_author = function () {
        $scope.author = [];
        $scope.author_id = [];
        $scope.number = 1;
        $scope.currentValue = 1;
        var current_url = window.location.pathname;
        window.history.pushState('url', 'Title', current_url);
        jQuery('.remove-auth').css({'z-index': '0'});
        jQuery('.info').removeClass('author-active');
        jQuery('.info').find('.remove-auth').empty();

    }

    $scope.author_click = function (id, name, des, term) {
        var current_url = window.location.pathname;
        $scope.currentValue = 1;
        window.history.pushState('url', 'Title', current_url);
        $scope.author = [];
        $scope.author_id = [];
        $scope.author.push({id: id, name: name, des: des, term: term});
        $scope.author_id.push(id);
        jQuery('.info').removeClass('author-active');
        jQuery('.info').find('.remove-auth').empty();
        jQuery('.remove-auth').css({'z-index': '0'});
        jQuery('.info').each(function () {
            if (jQuery(this).find('.col-four .title').attr('data-id') == id) {
                jQuery(this).find('.remove-auth').css({'z-index': '2'}).append('<img src="/wp-content/themes/sandhill/dist/images/x-red.svg" alt="close-btn">');
                jQuery(this).addClass('author-active');

            }
        });
        $scope.number = 1;
    }

    /*
     * Add active class and remove active class on click of category name for desktop
     */

    $scope.category_click = function (id, name) {
        $scope.currentValue = 1;
        jQuery('.category li').each(function () {
            if (jQuery(this).find('span').attr('data-id') == id) {
                if (jQuery(this).hasClass('category-active')) {
                    jQuery(this).removeClass('category-active');
                    for (var i = 0; i < $scope.category.length; i++) {
                        if ($scope.category[i]['id'] == id) {
                            $scope.category.splice(i, 1);
                        }
                    }
                    var found = jQuery.inArray(id, $scope.category_id);
                    if (found >= 0) {
                        $scope.category_id.splice(found, 1);
                    }
                } else {
                    $scope.category.push({id: id, name: name, class: 'desktop'});
                    $scope.category_id.push(id);
                    jQuery(this).addClass('category-active');
                }
                $scope.number = 1;
            }
        });
    }

    /*
     * Call function after filter apply
     */

    $scope.filter_temp = '';
    $filter.pagination = function (str) {
        $scope.count = str.length;
        var str1 = parseInt($scope.count / $scope.itemPerPage);
        var str2 = $scope.count % $scope.itemPerPage;
        if (str2) {
            str2 = 1;
        }
        $scope.pagination_number = str1 + str2;
        $scope.page_no = [];
        for (var i = 1; i <= $scope.pagination_number; i++) {
            $scope.last_page = $scope.pagination_number;
            $scope.page_no.push(i);
        }
        $scope.filter_temp = str;
    }

    /*
    * Clear All Filter
    */

    $scope.clear_all = function(){
        $scope.category=[];
        $scope.category_id=[];
        $scope.author =[];
        $scope.author_id=[];
        $scope.searchString = '';
        jQuery('.remove-auth').css({'z-index': '0'});
        jQuery('.info').removeClass('author-active');
        jQuery('.info').find('.remove-auth').empty();
        jQuery('.category li').removeClass('category-active');
        jQuery('.blog-landing .filter-wrap .form-outer form .clear-search').css({'opacity':'0', 'visibility':'hidden'});
    }

    /*
     * Use for set the layout of boxes.
     */

    $scope.styleFunction = function (index, feature, number, author_id, tag_id, category_id, searchString) {
        if (((index == 0) || (index == 6) || (index == 12)) && feature && (number =='' || number == 1) && (author_id == '') && (tag_id == '') && (category_id == '') && (searchString=='')) {
            if (index == 6) {
                return "col-seven right";
            } else {
                return "col-seven";
            }
        } else {
           return "col-three";
        }
    }


}]);



