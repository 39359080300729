app.controller('contactCtrl', ['$scope', '$http', function ($scope, $http) {
  /*
   * Contact Us page mail form handling
   */
  $scope.submitForm = function ($event) {

    $scope.formData = {
      'name': $scope.name,
      'g-recaptcha-response': grecaptcha.getResponse(),
      'email': $scope.email,
      'subject': $scope.subject,
      'message': $scope.message
    };
    $scope.submitted = true;
    if (grecaptcha.getResponse() == "") {
      jQuery('.g-recaptcha-err').html("You can't proceed! Please check reCAPTCHA to proceed.");
      jQuery('.g-recaptcha-err').css({'color': 'red'});
      return false;
    }
    if ((!$scope.ContactForm.$valid) && (grecaptcha.getResponse() == "")) {
    }
    else if (($scope.ContactForm.$valid) && (grecaptcha.getResponse() != "")) {
      jQuery('.g-recaptcha-err').empty();
      $http({
        method: 'POST',
        url: '/wp-content/themes/sandhill/templates/components/mailer.php',
        data: jQuery.param($scope.formData), // pass in data as strings
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}  // set the headers so angular passing info as form data (not request payload)
      }).then(
              function (response) {
                  /* Response */
                if(response.data == "1") {
                    jQuery('input, textarea').val('').removeClass('used');
                    jQuery('.contact-us .container .contact-form form .input-wrap').removeClass('focused');
                    jQuery('.contact-pop-up').css({
                        'opacity': '1',
                        'z-index': '2',
                        'transition': 'all .2s ease-in-out'
                    });
                    jQuery(".contact-pop-up .message-container").addClass("move-up").css({
                        "animation-duration": "0.8s",
                        "animation-delay": "0.3s",
                        "animation-name": "fadeInUp"
                    });
                    jQuery('.g-recaptcha-err').html("");
                    $scope.name = '';
                    $scope.email = '';
                    $scope.subject = '';
                    $scope.message = '';
                    ;
                    grecaptcha.reset();
                }
                else {
                    jQuery('.unsuccess').css({'display': 'block'});
                    jQuery('.unsuccess').html("Mail is not sent, Please try again");
                }

              },
              function (error) {
                jQuery('.unsuccess').css({'display': 'block'});
                jQuery('.unsuccess').html("Mail is not sent, Please try again");
              });
    }
  }

  /*
   * Footer Sign up form handling
   */

  jQuery(".news-sign-up input").focus(function () {
    jQuery('.news-sign-up .loading-sign-up').css({'display': 'none'}).empty();
    jQuery('.news-sign-up .success-sign-up').css({'display': 'none'});
  });

  $scope.signupForm = function ($event) {
    $scope.signupData = {
      'signupEmail': $scope.signupEmail,
    };
    $scope.signupsubmitted = true;
    jQuery('.news-sign-up .loading-sign-up').css({'display': 'block'}).append('<img src="/wp-content/themes/sandhill/dist/images/loader.gif" alt="Loading image">');
    if (!$scope.SignupForm.$valid) {
      jQuery('.news-sign-up .loading-sign-up').css({'display': 'none'}).empty();
      jQuery('.news-sign-up .success-sign-up').css({'display': 'none'});
      jQuery('.news-sign-up .alert-sign-up').css({'display': 'none'});
      jQuery('.news-sign-up .alert-sign-up').css({'display': 'block'});
    }
    else if ($scope.SignupForm.$valid) {
      $http({
        method: 'POST',
        url: '/wp-content/themes/sandhill/templates/components/signup-form/news-signup.php',
        data: jQuery.param($scope.signupData), // pass in data as strings
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}  // set the headers so angular passing info as form data (not request payload)
      }).then(
              function (result) {
                if (result.data.id) {
                  jQuery('.news-sign-up .loading-sign-up').css({'display': 'none'}).empty();
                  jQuery('.news-sign-up .success-sign-up').css({'display': 'none'});
                  jQuery('.news-sign-up .alert-sign-up').css({'display': 'none'});
                  jQuery('.news-sign-up .success-sign-up').css({'display': 'block'});
                  $scope.signupEmail = '';

                } else {
                  jQuery('.news-sign-up .loading-sign-up').css({'display': 'none'}).empty();
                  jQuery('.news-sign-up .success-sign-up').css({'display': 'none'});
                  jQuery('.news-sign-up .alert-sign-up').css({'display': 'none'});
                  jQuery('.news-sign-up .alert-sign-up').css({'display': 'block'});
                  $scope.signupEmail = '';
                }
              },
              function (error) {
              });
    }
  }

  /*
   * Cta Sign up form
   */

  jQuery(".cta-sign-up input").focus(function () {
    jQuery('.cta-sign-up .loading-sign-up').css({'display': 'none'}).empty();
    jQuery('.cta-sign-up .success-sign-up').css({'display': 'none'});
  });

  $scope.newsForm = function ($event) {
    $scope.newsletterData = {
      'newsletterEmail': $scope.newsEmail,
      'g-recaptcha-response': grecaptcha.getResponse()
    };
    $scope.newslettersubmitted = true;
    if ((grecaptcha.getResponse() == "") && ($scope.newsletterForm.$valid)) {
      jQuery('.g-recaptcha-err').html("You can't proceed! Please check reCAPTCHA to proceed.");
      jQuery('.g-recaptcha-err').css({'color': 'red'});
      return false;
    } else if ((!$scope.newsletterForm.$valid) && (grecaptcha.getResponse() == "")) {
      jQuery('.cta-sign-up .loading-sign-up').css({'display': 'none'}).empty();
      jQuery('.cta-sign-up .success-sign-up').css({'display': 'none'});
      jQuery('.cta-sign-up .alert-sign-up').css({'display': 'none'});
      jQuery('.cta-sign-up .alert-sign-up').css({'display': 'block'});
      jQuery('.g-recaptcha-err').html("You can't proceed! Please check reCAPTCHA to proceed.");
      jQuery('.g-recaptcha-err').css({'color': 'red'});
      return false;
    }
    jQuery('.cta-sign-up .loading-sign-up').css({'display': 'block'}).append('<img src="/wp-content/themes/sandhill/dist/images/loader.gif" alt="Loading image">');

    if ((!$scope.newsletterForm.$valid) && (grecaptcha.getResponse() == "")) {
      jQuery('.cta-sign-up .loading-sign-up').css({'display': 'none'}).empty();
      jQuery('.cta-sign-up .success-sign-up').css({'display': 'none'});
      jQuery('.cta-sign-up .alert-sign-up').css({'display': 'none'});
      jQuery('.cta-sign-up .alert-sign-up').css({'display': 'block'});
    } else if ((!$scope.newsletterForm.$valid) && (grecaptcha.getResponse() != "")) {
      jQuery('.cta-sign-up .loading-sign-up').css({'display': 'none'}).empty();
      jQuery('.cta-sign-up .success-sign-up').css({'display': 'none'});
      jQuery('.cta-sign-up .alert-sign-up').css({'display': 'none'});
      jQuery('.cta-sign-up .alert-sign-up').css({'display': 'block'});
    }
    else if (($scope.newsletterForm.$valid) && (grecaptcha.getResponse() != "")) {
      $http({
        method: 'POST',
        url: '/wp-content/themes/sandhill/templates/components/signup-form/signup.php',
        data: jQuery.param($scope.newsletterData), // pass in data as strings
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}  // set the headers so angular passing info as form data (not request payload)
      }).then(
              function (result) {
                $scope.newsEmail = '';
                jQuery('.g-recaptcha-err').html("");
                grecaptcha.reset();
                if (result.data.id) {
                  jQuery('.cta-sign-up .loading-sign-up').css({'display': 'none'}).empty();
                  jQuery('.cta-sign-up .success-sign-up').css({'display': 'none'});
                  jQuery('.cta-sign-up .alert-sign-up').css({'display': 'none'});
                  jQuery('.cta-sign-up .success-sign-up').css({'display': 'block'});
                  jQuery('.cta-sign-up .input-wrap').removeClass('focused');
                } else {
                  jQuery('.cta-sign-up .loading-sign-up').css({'display': 'none'}).empty();
                  jQuery('.cta-sign-up .success-sign-up').css({'display': 'none'});
                  jQuery('.cta-sign-up .alert-sign-up').css({'display': 'none'});
                  jQuery('.cta-sign-up .alert-sign-up').css({'display': 'block'});
                  jQuery('.cta-sign-up .input-wrap').removeClass('focused');
                }
              },
              function (error) {
              });
    }
  }

}]);
