app.directive('itemRepeatDirective', function () {
    return function (scope, element, attrs) {
        if (scope.$last) {
            setTimeout(function () {
                jQuery('.grid-outer').addClass('post-load');
                jQuery('.grid-outer, .pagination').css({'display': 'block'});
                jQuery('.loader').css({'display': 'none'});
            }, 100);
        }
    }
});
app.directive('paginationRepeatDirective', function () {
    return function (scope, element, attrs) {
        if (scope.$last) {
            setTimeout(function () {
                jQuery('.pagination').css({'display': 'block'});
            }, 400);
        }
    }
});
app.directive('authorRepeatDirective', function () {
    return function (scope, element, attrs) {
        if (scope.$last) {
            /*
            * Get parameter from url and add active class
            */
            var url = window.location.href;
            if (url.indexOf("?") > -1) {
                var urlString = url.substr(url.indexOf("?") + 1);
                if (urlString) {
                    var urlArray = urlString.split('=');
                    if (urlArray[0] == 'author_id') {

                        setTimeout(function () {
                            jQuery('.info-wrap > .info').each(function () {
                                if (jQuery(this).find('.col-four .title').attr('data-id') == urlArray[1]) {
                                    var update_string = jQuery(this).find('.col-four > .title .v-middle').text();
                                    if((update_string) && (update_string.indexOf( ',' ) > 0)){
                                        var pos = update_string.indexOf( ',' );
                                         var author_name = update_string.substring( 0, pos );
                                    }else{
                                        var author_name = update_string;
                                    }
                                    var des = jQuery(this).find('.col-four > .title .short_certification').text();
                                    scope.author.push({id: parseInt(urlArray[1]), name: author_name, des: des});
                                    scope.author_id.push(parseInt(urlArray[1]));
                                    scope.$apply();
                                    jQuery(this).addClass('author-active');
                                    jQuery(this).find('.remove-auth').append('<img src="/wp-content/themes/sandhill/dist/images/x-red.svg" alt="close-btn">');

                                }
                            });
                        }, 10);
                    } else if (urlArray[0] == 'tag_id') {
                        scope.tag_id.push(parseInt(urlArray[1]));
                    }
                }
            }
        }
    }
});

app.directive('clearsearch', ['$compile', function ($compile) {
    return{
        scope: true,
        restrict: 'C',
        replace: false,
        link: function (scope, element, attr) {
            element.bind('click', function ($event) {
                scope.clear_search();
                scope.$apply();
            });
        }
    }
}]);

app.directive('clearall', ['$compile', function ($compile) {
    return{
        scope: true,
        restrict: 'C',
        replace: false,
        link: function (scope, element, attr) {
            element.bind('click', function ($event) {
                scope.clear_all();
                scope.$apply();
            });
        }
    }
}]);

/*
* Custom filter
*/
app.filter('customFilter', [ '$filter', function ($filter) {
    return function (items, author_id, tag_id, category_id, tempitems, number, searchString) {
        var itemPerPage = 18; // set per page item number (predefine)
        var min, max;
        var result = [];
        var temp_result = [];
        var temp_result1 = [];
        var temp_result2 = [];
        var temp_result3 = [];

        if ((author_id == '') && (tag_id == '') && (category_id == '') && (number == '') && ((searchString == '') || (searchString == undefined))) {
            if (tempitems) {
                for (var i = 0; i < itemPerPage; i++) {
                    result.push(tempitems[i]);
                }
                $filter.pagination(tempitems);
            }
        }
        else if ((author_id == '') && (tag_id == '') && (category_id == '') && (number !== '') && ((searchString == '') || (searchString == undefined))) {
            if (tempitems) {
                max = itemPerPage * number;
                min = max - itemPerPage;
                if (max > tempitems.length) {
                    max = tempitems.length;
                }
                $filter.pagination(tempitems);
                for (var i = min; i < max; i++) {
                    result.push(tempitems[i]);
                }
            }

        }

        if (number == '') {
            number = 1;
        }

        if ((author_id == '') && (tag_id == '') && (category_id == '') && (searchString != '')) {
            if (searchString) {
                searchString = searchString.toLowerCase();
            }
            angular.forEach(tempitems, function (item) {
                if ((item.title.toLowerCase().indexOf(searchString) !== -1)||(item.content.toLowerCase().indexOf(searchString) !== -1)) {
                    temp_result.push(item);
                }
            });
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id != '') && (tag_id == '') && (category_id == '') && ((searchString == '') || (searchString == undefined))) {
            for (var i = 0; i < author_id.length; i++) {
                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j].author_id) {
                        if (String(tempitems[j].author_id).indexOf(author_id[i]) >= 0) {
                            var found = jQuery.inArray(tempitems[j], temp_result);
                            if (found < 0) {
                                temp_result.push(tempitems[j]);
                            }
                        }
                    }
                }
            }
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id == '') && (tag_id != '') && (category_id == '') && ((searchString == '') || (searchString == undefined))) {
            for (var i = 0; i < tag_id.length; i++) {

                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j]["tags"].length > 0) {
                        for (var k = 0; k < tempitems[j]["tags"].length; k++) {
                            if (String(tempitems[j]["tags"][k].id) == String(tag_id[i])) {
                                var found = jQuery.inArray(tempitems[j], temp_result);
                                if (found < 0) {
                                    temp_result.push(tempitems[j]);
                                }
                            }
                        }

                    }
                }
            }
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id == '') && (tag_id == '') && (category_id != '') && ((searchString == '') || (searchString == undefined))) {
            for (var i = 0; i < category_id.length; i++) {
                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j]["category"].length > 0) {
                        for (var k = 0; k < tempitems[j]["category"].length; k++) {
                            if (String(tempitems[j]["category"][k].id) == String(category_id[i])) {
                                var found = jQuery.inArray(tempitems[j], temp_result);
                                if (found < 0) {
                                    temp_result.push(tempitems[j]);
                                }
                            }
                        }

                    }
                }
            }
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id != '') && (tag_id == '') && (category_id == '') && (searchString != '')) {
            for (var i = 0; i < author_id.length; i++) {
                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j].author_id) {
                        if (String(tempitems[j].author_id).indexOf(author_id[i]) >= 0) {
                            var found = jQuery.inArray(tempitems[j], temp_result1);
                            if (found < 0) {
                                temp_result1.push(tempitems[j]);
                            }
                        }
                    }
                }
            }
            if (searchString) {
                searchString = searchString.toLowerCase();
            }
            angular.forEach(temp_result1, function (item) {

                if ((item.title.toLowerCase().indexOf(searchString) !== -1)||(item.content.toLowerCase().indexOf(searchString) !== -1)) {
                    temp_result.push(item);
                }

            });
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id == '') && (tag_id != '') && (category_id == '') && (searchString != '')) {
            for (var i = 0; i < tag_id.length; i++) {

                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j]["tags"].length > 0) {
                        for (var k = 0; k < tempitems[j]["tags"].length; k++) {
                            if (String(tempitems[j]["tags"][k].id) == String(tag_id[i])) {
                                var found = jQuery.inArray(tempitems[j], temp_result1);
                                if (found < 0) {
                                    temp_result1.push(tempitems[j]);
                                }
                            }
                        }

                    }
                }
            }
            if (searchString) {
                searchString = searchString.toLowerCase();
            }
            angular.forEach(temp_result1, function (item) {

                if ((item.title.toLowerCase().indexOf(searchString) !== -1)||(item.content.toLowerCase().indexOf(searchString) !== -1)) {
                    temp_result.push(item);
                }

            });
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id == '') && (tag_id == '') && (category_id != '') && (searchString != '')) {
            for (var i = 0; i < category_id.length; i++) {
                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j]["category"].length > 0) {
                        for (var k = 0; k < tempitems[j]["category"].length; k++) {
                            if (String(tempitems[j]["category"][k].id) == String(category_id[i])) {
                                var found = jQuery.inArray(tempitems[j], temp_result1);
                                if (found < 0) {
                                    temp_result1.push(tempitems[j]);
                                }
                            }
                        }

                    }
                }
            }
            if (searchString) {
                searchString = searchString.toLowerCase();
            }
            angular.forEach(temp_result1, function (item) {

                if ((item.title.toLowerCase().indexOf(searchString) !== -1)||(item.content.toLowerCase().indexOf(searchString) !== -1)) {
                    temp_result.push(item);
                }

            });
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id != '') && (tag_id != '') && (category_id == '') && (searchString == '')) {
            for (var i = 0; i < author_id.length; i++) {
                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j].author_id) {
                        if (String(tempitems[j].author_id).indexOf(author_id[i]) >= 0) {
                            var found = jQuery.inArray(tempitems[j], temp_result1);
                            if (found < 0) {
                                temp_result1.push(tempitems[j]);
                            }
                        }
                    }
                }
            }
            for (var i = 0; i < tag_id.length; i++) {
                for (var j = 0; j < temp_result1.length; j++) {
                    if (temp_result1[j]["tags"].length > 0) {
                        for (var k = 0; k < temp_result1[j]["tags"].length; k++) {
                            if (String(temp_result1[j]["tags"][k].id) == String(tag_id[i])) {
                                var found = jQuery.inArray(temp_result1[j], temp_result);
                                if (found < 0) {
                                    temp_result.push(temp_result1[j]);
                                }
                            }
                        }

                    }
                }
            }
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id != '') && (tag_id == '') && (category_id != '') && (searchString == '')) {
            for (var i = 0; i < author_id.length; i++) {
                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j].author_id) {
                        if (String(tempitems[j].author_id).indexOf(author_id[i]) >= 0) {
                            var found = jQuery.inArray(tempitems[j], temp_result1);
                            if (found < 0) {
                                temp_result1.push(tempitems[j]);
                            }
                        }
                    }
                }
            }
            for (var i = 0; i < category_id.length; i++) {
                for (var j = 0; j < temp_result1.length; j++) {
                    if (temp_result1[j]["category"].length > 0) {
                        for (var k = 0; k < temp_result1[j]["category"].length; k++) {
                            if (String(temp_result1[j]["category"][k].id) == String(category_id[i])) {
                                var found = jQuery.inArray(temp_result1[j], temp_result);
                                if (found < 0) {
                                    temp_result.push(temp_result1[j]);
                                }
                            }
                        }

                    }
                }
            }
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id != '') && (tag_id != '') && (category_id == '') && (searchString != '')) {
            for (var i = 0; i < author_id.length; i++) {
                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j].author_id) {
                        if (String(tempitems[j].author_id).indexOf(author_id[i]) >= 0) {
                            var found = jQuery.inArray(tempitems[j], temp_result1);
                            if (found < 0) {
                                temp_result1.push(tempitems[j]);
                            }
                        }
                    }
                }
            }
            for (var i = 0; i < tag_id.length; i++) {
                for (var j = 0; j < temp_result1.length; j++) {
                    if (temp_result1[j]["tags"].length > 0) {
                        for (var k = 0; k < temp_result1[j]["tags"].length; k++) {
                            if (String(temp_result1[j]["tags"][k].id) == String(tag_id[i])) {
                                var found = jQuery.inArray(temp_result1[j], temp_result2);
                                if (found < 0) {
                                    temp_result2.push(temp_result1[j]);
                                }
                            }
                        }

                    }
                }
            }
            if (searchString) {
                searchString = searchString.toLowerCase();
            }
            angular.forEach(temp_result2, function (item) {

                if ((item.title.toLowerCase().indexOf(searchString) !== -1)||(item.content.toLowerCase().indexOf(searchString) !== -1)) {
                    temp_result.push(item);
                }

            });
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id != '') && (tag_id == '') && (category_id != '') && (searchString != '')) {
            for (var i = 0; i < author_id.length; i++) {
                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j].author_id) {
                        if (String(tempitems[j].author_id).indexOf(author_id[i]) >= 0) {
                            var found = jQuery.inArray(tempitems[j], temp_result1);
                            if (found < 0) {
                                temp_result1.push(tempitems[j]);
                            }
                        }
                    }
                }
            }
            for (var i = 0; i < category_id.length; i++) {
                for (var j = 0; j < temp_result1.length; j++) {
                    if (temp_result1[j]["category"].length > 0) {
                        for (var k = 0; k < temp_result1[j]["category"].length; k++) {
                            if (String(temp_result1[j]["category"][k].id) == String(category_id[i])) {
                                var found = jQuery.inArray(temp_result1[j], temp_result2);
                                if (found < 0) {
                                    temp_result2.push(temp_result1[j]);
                                }
                            }
                        }

                    }
                }
            }
            if (searchString) {
                searchString = searchString.toLowerCase();
            }
            angular.forEach(temp_result2, function (item) {

                if ((item.title.toLowerCase().indexOf(searchString) !== -1)||(item.content.toLowerCase().indexOf(searchString) !== -1)) {
                    temp_result.push(item);
                }

            });
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id == '') && (tag_id != '') && (category_id != '') && (searchString == '')) {
            for (var i = 0; i < tag_id.length; i++) {
                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j]["tags"].length > 0) {
                        for (var k = 0; k < tempitems[j]["tags"].length; k++) {
                            if (String(tempitems[j]["tags"][k].id) == String(tag_id[i])) {
                                var found = jQuery.inArray(tempitems[j], temp_result1);
                                if (found < 0) {
                                    temp_result1.push(tempitems[j]);
                                }
                            }
                        }

                    }
                }
            }
            for (var i = 0; i < category_id.length; i++) {
                for (var j = 0; j < temp_result1.length; j++) {
                    if (temp_result1[j]["category"].length > 0) {
                        for (var k = 0; k < temp_result1[j]["category"].length; k++) {
                            if (String(temp_result1[j]["category"][k].id) == String(category_id[i])) {
                                var found = jQuery.inArray(temp_result1[j], temp_result);
                                if (found < 0) {
                                    temp_result.push(temp_result1[j]);
                                }
                            }
                        }

                    }
                }
            }
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id == '') && (tag_id != '') && (category_id != '') && (searchString != '')) {
            for (var i = 0; i < tag_id.length; i++) {
                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j]["tags"].length > 0) {
                        for (var k = 0; k < tempitems[j]["tags"].length; k++) {
                            if (String(tempitems[j]["tags"][k].id) == String(tag_id[i])) {
                                var found = jQuery.inArray(tempitems[j], temp_result1);
                                if (found < 0) {
                                    temp_result1.push(tempitems[j]);
                                }
                            }
                        }

                    }
                }
            }
            for (var i = 0; i < category_id.length; i++) {
                for (var j = 0; j < temp_result1.length; j++) {
                    if (temp_result1[j]["category"].length > 0) {
                        for (var k = 0; k < temp_result1[j]["category"].length; k++) {
                            if (String(temp_result1[j]["category"][k].id) == String(category_id[i])) {
                                var found = jQuery.inArray(temp_result1[j], temp_result2);
                                if (found < 0) {
                                    temp_result2.push(temp_result1[j]);
                                }
                            }
                        }

                    }
                }
            }
            if (searchString) {
                searchString = searchString.toLowerCase();
            }
            angular.forEach(temp_result2, function (item) {

                if ((item.title.toLowerCase().indexOf(searchString) !== -1)||(item.content.toLowerCase().indexOf(searchString) !== -1)) {
                    temp_result.push(item);
                }

            });
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id != '') && (tag_id != '') && (category_id != '') && (searchString == '')) {
            for (var i = 0; i < tag_id.length; i++) {
                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j]["tags"].length > 0) {
                        for (var k = 0; k < tempitems[j]["tags"].length; k++) {
                            if (String(tempitems[j]["tags"][k].id) == String(tag_id[i])) {
                                var found = jQuery.inArray(tempitems[j], temp_result1);
                                if (found < 0) {
                                    temp_result1.push(tempitems[j]);
                                }
                            }
                        }

                    }
                }
            }
            for (var i = 0; i < category_id.length; i++) {
                for (var j = 0; j < temp_result1.length; j++) {
                    if (temp_result1[j]["category"].length > 0) {
                        for (var k = 0; k < temp_result1[j]["category"].length; k++) {
                            if (String(temp_result1[j]["category"][k].id) == String(category_id[i])) {
                                var found = jQuery.inArray(temp_result1[j], temp_result2);
                                if (found < 0) {
                                    temp_result2.push(temp_result1[j]);
                                }
                            }
                        }

                    }
                }
            }
            for (var i = 0; i < author_id.length; i++) {
                for (var j = 0; j < temp_result2.length; j++) {
                    if (temp_result2[j].author_id) {
                        if (String(temp_result2[j].author_id) == String(author_id[i])) {
                            var found = jQuery.inArray(temp_result2[j], temp_result);
                            if (found < 0) {
                                temp_result.push(temp_result2[j]);
                            }
                        }
                    }
                }
            }
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }

        if ((author_id != '') && (tag_id != '') && (category_id != '') && (searchString != '')) {
            for (var i = 0; i < tag_id.length; i++) {
                for (var j = 0; j < tempitems.length; j++) {
                    if (tempitems[j]["tags"].length > 0) {
                        for (var k = 0; k < tempitems[j]["tags"].length; k++) {
                            if (String(tempitems[j]["tags"][k].id) == String(tag_id[i])) {
                                var found = jQuery.inArray(tempitems[j], temp_result1);
                                if (found < 0) {
                                    temp_result1.push(tempitems[j]);
                                }
                            }
                        }

                    }
                }
            }
            for (var i = 0; i < category_id.length; i++) {
                for (var j = 0; j < temp_result1.length; j++) {
                    if (temp_result1[j]["category"].length > 0) {
                        for (var k = 0; k < temp_result1[j]["category"].length; k++) {
                            if (String(temp_result1[j]["category"][k].id) == String(category_id[i])) {
                                var found = jQuery.inArray(temp_result1[j], temp_result2);
                                if (found < 0) {
                                    temp_result2.push(temp_result1[j]);
                                }
                            }
                        }

                    }
                }
            }
            for (var i = 0; i < author_id.length; i++) {
                for (var j = 0; j < temp_result2.length; j++) {
                    if (temp_result2[j].author_id) {
                        if (String(temp_result2[j].author_id) == String(author_id[i])) {
                            var found = jQuery.inArray(temp_result2[j], temp_result3);
                            if (found < 0) {
                                temp_result3.push(temp_result2[j]);
                            }
                        }
                    }
                }
            }
            if (searchString) {
                searchString = searchString.toLowerCase();
            }
            angular.forEach(temp_result3, function (item) {

                if ((item.title.toLowerCase().indexOf(searchString) !== -1)||(item.content.toLowerCase().indexOf(searchString) !== -1)) {
                    temp_result.push(item);
                }

            });
            max = itemPerPage * number;
            min = max - itemPerPage;
            if (max > temp_result.length) {
                max = temp_result.length;
            }
            $filter.pagination(temp_result);
            for (var i = min; i < max; i++) {
                result.push(temp_result[i]);
            }
        }
        return result;
    }
}]);


